// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// VERTICAL TABS
$fixed-tab-size: 8rem;
$left-tabs-text-align: left;
$right-tabs-text-align: right;

// Magic numbers
$magic-horizontal-value: 3.2rem;
$magic-vertical-value: 5rem;
$sideways-tabs-height: 1rem;

// Placeholders
%sideways-tabs {
  margin-top: $magic-vertical-value;
  border: none;
  position: relative;
  margin-bottom: 0;
}

// Used in vertical tab configuration (wide view)
%flex-tabs {
  height: 100%;
  flex-direction: column;
}

@mixin limit-text-length {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Mixins (can be reused inside @media scope)
@mixin fixed-tab($size: $fixed-tab-size) {
  width: $size;
  text-align: center;
  @include limit-text-length;
}

// Used in horizontal tab configuration (narrow views)
@mixin flex-tabs-h {
  flex-direction: row;
  border-right: none;
  border-left: none;
  min-width: 100%;
}

.ellipsis {
  @include limit-text-length;
  padding-right: 0.5rem;
}

// Workaround for Safari issue :/
// Bootstrap 5 uses <button type="button"> for tabs, but
// <button> doesn't work for vertical orientation, and applying type="button"
// to another element (e.g. <div>) will render it in Safari as an "early 21st century style" button,
// with gray gradient, border, etc. So we don't set type="button", but then we need
// to change the cursor to indicate that the tab is "clickable"
.vtabs .tab-clickable {
  cursor: pointer;
}

// Left vertical tabs
.left-tabs.nav-tabs {
  @extend %flex-tabs;
  // border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: 3px solid $success;
  border-bottom: none;
}

.left-tabs .nav-link {
  border-top-left-radius: $nav-tabs-border-radius;
  border-bottom-left-radius: $nav-tabs-border-radius;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -$nav-tabs-border-width;
  text-align: $left-tabs-text-align;
  padding: .25rem 1rem;

  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid transparent;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
  margin-bottom: .2rem;
}

.left-tabs .nav-link:hover {
  border-right: $nav-tabs-border-width solid transparent;
  background-color: $light;
}

.left-tabs .nav-link.active {
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid transparent;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
  color: $link-color;
  background-color: $primary;
}

.left-tabs a, .card a.stretched-link {
    text-decoration: none;
}

// Right vertical tabs
.right-tabs.nav-tabs {
  @extend %flex-tabs;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: none;
}

.right-tabs .nav-link {
  border-top-right-radius: $nav-tabs-border-radius;
  border-bottom-right-radius: $nav-tabs-border-radius;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -$nav-tabs-border-width;
  text-align: $right-tabs-text-align;
}

.right-tabs .nav-link:hover {
  border-left: $nav-tabs-border-width solid transparent;
}

.right-tabs .nav-link.active {
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-left: $nav-tabs-border-width solid transparent;
}

// Sideways left vertical tabs
.left-tabs.sideways-tabs {
  @extend %sideways-tabs;
  @extend %flex-tabs;
  border-right: none;
  left: -$magic-horizontal-value;
}

.sideways-tabs.left-tabs .nav-item {
  transform: rotate(-90deg); // ccw
  height: $sideways-tabs-height;
  margin-bottom: calc(#{$fixed-tab-size} - #{$sideways-tabs-height});
}

// NOTE: all top-bottom-left-right are before the rotation transform
.sideways-tabs.left-tabs .nav-link {
  @include fixed-tab;
  border-top-right-radius: $nav-tabs-border-radius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: $nav-tabs-border-radius;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}

.sideways-tabs.left-tabs .nav-link:hover {
  border-right: $nav-tabs-border-width solid $gray-200;
}

.sideways-tabs.left-tabs .nav-link.active {
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: $nav-tabs-border-width solid transparent;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
}

// Sideways right vertical tabs
.right-tabs.sideways-tabs {
  @extend %sideways-tabs;
  @extend %flex-tabs;
  border-left: none;
  right: $magic-horizontal-value;
}

.sideways-tabs.right-tabs .nav-item {
  transform: rotate(90deg); // cw
  height: $sideways-tabs-height;
  margin-bottom: calc(#{$fixed-tab-size} - #{$sideways-tabs-height});
}

// NOTE: all top-bottom-left-right are before the rotation transform
.sideways-tabs.right-tabs .nav-link {
  @include fixed-tab;
  border-top-right-radius: $nav-tabs-border-radius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: $nav-tabs-border-radius;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}

.sideways-tabs.right-tabs .nav-link:hover {
  border-left: $nav-tabs-border-width solid $gray-200;
}

.sideways-tabs.right-tabs .nav-link.active {
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: $nav-tabs-border-width solid transparent;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
}

// Do not display accordion when in tab mode (wide view)
.vtabs .accordion-header {
  display: none;
}

// FLAGS
@import 'flags';

// DISKOGRAFIE
.main-title {
    color: $secondary;
    text-align: center;
}

.card:hover, .card.active {
    box-shadow: 0px 1px 3px 0px rgba(60, 60, 60, 0.3);
    background-color: $gray-200;
}

pre {
    font-size: 1em;
    line-height: 1.35;
}

.song-text p {
    margin-bottom: 0;
    letter-spacing: -0.5px;
    font-size: 105%;    
}

ul.ais-RefinementList-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.ais-RefinementList-item {
    list-style: none;
    display: inline-block;
    width: calc(100% / 3);
    text-align: center;
}

.ais-RefinementList-checkbox {
    margin-right: 0.25rem !important;
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
}

.ais-RefinementList-count {
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem;
    background-color: var(--bs-info);
    color: var(--bs-white);
    border-radius: 8px;
    margin-left: 0.2rem;
}

ol.ais-Hits-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ais-Hits-item a {
    text-decoration: none;
}

.ais-Hits-item a div.media {
    padding: 0.25rem;
    width: 100%;
    border: 1px solid var(--bs-gray-400);
    border-radius: var(--bs-border-radius) !important;
    margin-bottom: 0.5em;
}

.ais-Hits-item a:hover div.media {
    background-color: var(--bs-gray-200);
}

.ais-Hits-item a:hover .hit-info {
    color: var(--bs-gray-700) !important;    
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.ais-Hits-item img {
    margin-right: 1em;
    max-width: 4em;
}

.ais-Hits-item svg {
    fill: var(--bs-gray-300);
    margin-right: 1em;
    max-width: 3em;
}

.ais-Highlight {
    color: var(--bs-teal);
    font-weight: bold;
    margin-bottom: 0.5em;
}

.ais-Highlight-highlighted {
    color: var(--bs-info);
    background: inherit;
}

.hit-info {
    font-size: 90%;
    font-style: italic;
    color: var(--bs-gray-300) !important;    
}

.hit-description {
    font-size: 90%;
    margin-bottom: 0.5em;
    color: var(--bs-gray-300);
}

.ais-InstantSearch {
    position: relative;
}

.ais-SearchBox-form {
    position: relative;
}

.ais-SearchBox-input {
    padding: 0.3rem 0.5rem 0.3rem 1.7rem;
    width: 100%;
}

.ais-SearchBox-submit {
    left: 0.3rem;
}

.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset,
.ais-SearchBox-submit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 0rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
}

.ais-SearchBox-submitIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.ais-SearchBox-reset {
    right: 0.3rem;
    display: none;
}

.ais-Pagination {
    margin: 2rem auto;
    text-align: center;
}

.ais-SearchBox-form {
    margin-bottom: 0px;
}

.ais-SearchBox-input {
    @extend .form-control;
}

.ais-StateResults {
    position: relative;
}

.filter-RefinementList {
    border: 1px solid var(--bs-gray-400);
    border-radius: var(--bs-border-radius) !important;
}

.ais-StateResults-inner {
    @extend .dropdown-menu;
    @extend .dropdown-menu-dark;
    @extend .dropdown-menu-end;
    width: 95vw;
    left: 50% !important;
    transform: translateX(-50%) !important;
}

@include media-breakpoint-only(sm) {
    .ais-StateResults-inner {
        width: 70vw;
    }
}

@include media-breakpoint-up(md) {
    .ais-StateResults-inner {
        width: 40vw;
        inset: 0em -0.1em auto auto !important;
        transform: none !important;
    }
}

@include media-breakpoint-up(xxl) {
    .ais-StateResults-inner {
        width: 30vw;
    }
}