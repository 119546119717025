
.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
  }
  .flag-icon:before {
    content: "\00a0";
  }
  .flag-icon.flag-icon-squared {
    width: 1em;
  }
  .flag-icon-ad,
  .flag-icon-and {
    background-image: url(/images/flags/4x3/ad.svg);
  }
  .flag-icon-ad.flag-icon-squared,
  .flag-icon-and.flag-icon-squared {
    background-image: url(/images/flags/1x1/ad.svg);
  }
  .flag-icon-ae,
  .flag-icon-are {
    background-image: url(/images/flags/4x3/ae.svg);
  }
  .flag-icon-ae.flag-icon-squared,
  .flag-icon-are.flag-icon-squared {
    background-image: url(/images/flags/1x1/ae.svg);
  }
  .flag-icon-af,
  .flag-icon-afg {
    background-image: url(/images/flags/4x3/af.svg);
  }
  .flag-icon-af.flag-icon-squared,
  .flag-icon-afg.flag-icon-squared {
    background-image: url(/images/flags/1x1/af.svg);
  }
  .flag-icon-ag,
  .flag-icon-atg {
    background-image: url(/images/flags/4x3/ag.svg);
  }
  .flag-icon-ag.flag-icon-squared,
  .flag-icon-atg.flag-icon-squared {
    background-image: url(/images/flags/1x1/ag.svg);
  }
  .flag-icon-ai,
  .flag-icon-aia {
    background-image: url(/images/flags/4x3/ai.svg);
  }
  .flag-icon-ai.flag-icon-squared,
  .flag-icon-aia.flag-icon-squared {
    background-image: url(/images/flags/1x1/ai.svg);
  }
  .flag-icon-al,
  .flag-icon-alb {
    background-image: url(/images/flags/4x3/al.svg);
  }
  .flag-icon-al.flag-icon-squared,
  .flag-icon-alb.flag-icon-squared {
    background-image: url(/images/flags/1x1/al.svg);
  }
  .flag-icon-am,
  .flag-icon-arm {
    background-image: url(/images/flags/4x3/am.svg);
  }
  .flag-icon-am.flag-icon-squared,
  .flag-icon-arm.flag-icon-squared {
    background-image: url(/images/flags/1x1/am.svg);
  }
  .flag-icon-ao,
  .flag-icon-ago {
    background-image: url(/images/flags/4x3/ao.svg);
  }
  .flag-icon-ao.flag-icon-squared,
  .flag-icon-ago.flag-icon-squared {
    background-image: url(/images/flags/1x1/ao.svg);
  }
  .flag-icon-aq,
  .flag-icon-ata {
    background-image: url(/images/flags/4x3/aq.svg);
  }
  .flag-icon-aq.flag-icon-squared,
  .flag-icon-ata.flag-icon-squared {
    background-image: url(/images/flags/1x1/aq.svg);
  }
  .flag-icon-ar,
  .flag-icon-arg {
    background-image: url(/images/flags/4x3/ar.svg);
  }
  .flag-icon-ar.flag-icon-squared,
  .flag-icon-arg.flag-icon-squared {
    background-image: url(/images/flags/1x1/ar.svg);
  }
  .flag-icon-as,
  .flag-icon-asm {
    background-image: url(/images/flags/4x3/as.svg);
  }
  .flag-icon-as.flag-icon-squared,
  .flag-icon-asm.flag-icon-squared {
    background-image: url(/images/flags/1x1/as.svg);
  }
  .flag-icon-at,
  .flag-icon-aut {
    background-image: url(/images/flags/4x3/at.svg);
  }
  .flag-icon-at.flag-icon-squared,
  .flag-icon-aut.flag-icon-squared {
    background-image: url(/images/flags/1x1/at.svg);
  }
  .flag-icon-au,
  .flag-icon-aus {
    background-image: url(/images/flags/4x3/au.svg);
  }
  .flag-icon-au.flag-icon-squared,
  .flag-icon-aus.flag-icon-squared {
    background-image: url(/images/flags/1x1/au.svg);
  }
  .flag-icon-aw,
  .flag-icon-abw {
    background-image: url(/images/flags/4x3/aw.svg);
  }
  .flag-icon-aw.flag-icon-squared,
  .flag-icon-abw.flag-icon-squared {
    background-image: url(/images/flags/1x1/aw.svg);
  }
  .flag-icon-ax,
  .flag-icon-ala {
    background-image: url(/images/flags/4x3/ax.svg);
  }
  .flag-icon-ax.flag-icon-squared,
  .flag-icon-ala.flag-icon-squared {
    background-image: url(/images/flags/1x1/ax.svg);
  }
  .flag-icon-az,
  .flag-icon-aze {
    background-image: url(/images/flags/4x3/az.svg);
  }
  .flag-icon-az.flag-icon-squared,
  .flag-icon-aze.flag-icon-squared {
    background-image: url(/images/flags/1x1/az.svg);
  }
  .flag-icon-ba,
  .flag-icon-bih {
    background-image: url(/images/flags/4x3/ba.svg);
  }
  .flag-icon-ba.flag-icon-squared,
  .flag-icon-bih.flag-icon-squared {
    background-image: url(/images/flags/1x1/ba.svg);
  }
  .flag-icon-bb,
  .flag-icon-brb {
    background-image: url(/images/flags/4x3/bb.svg);
  }
  .flag-icon-bb.flag-icon-squared,
  .flag-icon-brb.flag-icon-squared {
    background-image: url(/images/flags/1x1/bb.svg);
  }
  .flag-icon-bd,
  .flag-icon-bgd {
    background-image: url(/images/flags/4x3/bd.svg);
  }
  .flag-icon-bd.flag-icon-squared,
  .flag-icon-bgd.flag-icon-squared {
    background-image: url(/images/flags/1x1/bd.svg);
  }
  .flag-icon-be,
  .flag-icon-bel {
    background-image: url(/images/flags/4x3/be.svg);
  }
  .flag-icon-be.flag-icon-squared,
  .flag-icon-bel.flag-icon-squared {
    background-image: url(/images/flags/1x1/be.svg);
  }
  .flag-icon-bf,
  .flag-icon-bfa {
    background-image: url(/images/flags/4x3/bf.svg);
  }
  .flag-icon-bf.flag-icon-squared,
  .flag-icon-bfa.flag-icon-squared {
    background-image: url(/images/flags/1x1/bf.svg);
  }
  .flag-icon-bg,
  .flag-icon-bgr {
    background-image: url(/images/flags/4x3/bg.svg);
  }
  .flag-icon-bg.flag-icon-squared,
  .flag-icon-bgr.flag-icon-squared {
    background-image: url(/images/flags/1x1/bg.svg);
  }
  .flag-icon-bh,
  .flag-icon-bhr {
    background-image: url(/images/flags/4x3/bh.svg);
  }
  .flag-icon-bh.flag-icon-squared,
  .flag-icon-bhr.flag-icon-squared {
    background-image: url(/images/flags/1x1/bh.svg);
  }
  .flag-icon-bi,
  .flag-icon-bdi {
    background-image: url(/images/flags/4x3/bi.svg);
  }
  .flag-icon-bi.flag-icon-squared,
  .flag-icon-bdi.flag-icon-squared {
    background-image: url(/images/flags/1x1/bi.svg);
  }
  .flag-icon-bj,
  .flag-icon-ben {
    background-image: url(/images/flags/4x3/bj.svg);
  }
  .flag-icon-bj.flag-icon-squared,
  .flag-icon-ben.flag-icon-squared {
    background-image: url(/images/flags/1x1/bj.svg);
  }
  .flag-icon-bl,
  .flag-icon-blm {
    background-image: url(/images/flags/4x3/bl.svg);
  }
  .flag-icon-bl.flag-icon-squared,
  .flag-icon-blm.flag-icon-squared {
    background-image: url(/images/flags/1x1/bl.svg);
  }
  .flag-icon-bm,
  .flag-icon-bmu {
    background-image: url(/images/flags/4x3/bm.svg);
  }
  .flag-icon-bm.flag-icon-squared,
  .flag-icon-bmu.flag-icon-squared {
    background-image: url(/images/flags/1x1/bm.svg);
  }
  .flag-icon-bn,
  .flag-icon-brn {
    background-image: url(/images/flags/4x3/bn.svg);
  }
  .flag-icon-bn.flag-icon-squared,
  .flag-icon-brn.flag-icon-squared {
    background-image: url(/images/flags/1x1/bn.svg);
  }
  .flag-icon-bo,
  .flag-icon-bol {
    background-image: url(/images/flags/4x3/bo.svg);
  }
  .flag-icon-bo.flag-icon-squared,
  .flag-icon-bol.flag-icon-squared {
    background-image: url(/images/flags/1x1/bo.svg);
  }
  .flag-icon-bq,
  .flag-icon-bes {
    background-image: url(/images/flags/4x3/bq.svg);
  }
  .flag-icon-bq.flag-icon-squared,
  .flag-icon-bes.flag-icon-squared {
    background-image: url(/images/flags/1x1/bq.svg);
  }
  .flag-icon-br,
  .flag-icon-bra {
    background-image: url(/images/flags/4x3/br.svg);
  }
  .flag-icon-br.flag-icon-squared,
  .flag-icon-bra.flag-icon-squared {
    background-image: url(/images/flags/1x1/br.svg);
  }
  .flag-icon-bs,
  .flag-icon-bhs {
    background-image: url(/images/flags/4x3/bs.svg);
  }
  .flag-icon-bs.flag-icon-squared,
  .flag-icon-bhs.flag-icon-squared {
    background-image: url(/images/flags/1x1/bs.svg);
  }
  .flag-icon-bt,
  .flag-icon-btn {
    background-image: url(/images/flags/4x3/bt.svg);
  }
  .flag-icon-bt.flag-icon-squared,
  .flag-icon-btn.flag-icon-squared {
    background-image: url(/images/flags/1x1/bt.svg);
  }
  .flag-icon-bv,
  .flag-icon-bvt {
    background-image: url(/images/flags/4x3/bv.svg);
  }
  .flag-icon-bv.flag-icon-squared,
  .flag-icon-bvt.flag-icon-squared {
    background-image: url(/images/flags/1x1/bv.svg);
  }
  .flag-icon-bw,
  .flag-icon-bwa {
    background-image: url(/images/flags/4x3/bw.svg);
  }
  .flag-icon-bw.flag-icon-squared,
  .flag-icon-bwa.flag-icon-squared {
    background-image: url(/images/flags/1x1/bw.svg);
  }
  .flag-icon-by,
  .flag-icon-blr {
    background-image: url(/images/flags/4x3/by.svg);
  }
  .flag-icon-by.flag-icon-squared,
  .flag-icon-blr.flag-icon-squared {
    background-image: url(/images/flags/1x1/by.svg);
  }
  .flag-icon-bz,
  .flag-icon-blz {
    background-image: url(/images/flags/4x3/bz.svg);
  }
  .flag-icon-bz.flag-icon-squared,
  .flag-icon-blz.flag-icon-squared {
    background-image: url(/images/flags/1x1/bz.svg);
  }
  .flag-icon-ca,
  .flag-icon-can {
    background-image: url(/images/flags/4x3/ca.svg);
  }
  .flag-icon-ca.flag-icon-squared,
  .flag-icon-can.flag-icon-squared {
    background-image: url(/images/flags/1x1/ca.svg);
  }
  .flag-icon-cc,
  .flag-icon-cck {
    background-image: url(/images/flags/4x3/cc.svg);
  }
  .flag-icon-cc.flag-icon-squared,
  .flag-icon-cck.flag-icon-squared {
    background-image: url(/images/flags/1x1/cc.svg);
  }
  .flag-icon-cd,
  .flag-icon-cod {
    background-image: url(/images/flags/4x3/cd.svg);
  }
  .flag-icon-cd.flag-icon-squared,
  .flag-icon-cod.flag-icon-squared {
    background-image: url(/images/flags/1x1/cd.svg);
  }
  .flag-icon-cf,
  .flag-icon-caf {
    background-image: url(/images/flags/4x3/cf.svg);
  }
  .flag-icon-cf.flag-icon-squared,
  .flag-icon-caf.flag-icon-squared {
    background-image: url(/images/flags/1x1/cf.svg);
  }
  .flag-icon-cg,
  .flag-icon-cog {
    background-image: url(/images/flags/4x3/cg.svg);
  }
  .flag-icon-cg.flag-icon-squared,
  .flag-icon-cog.flag-icon-squared {
    background-image: url(/images/flags/1x1/cg.svg);
  }
  .flag-icon-ch,
  .flag-icon-che {
    background-image: url(/images/flags/4x3/ch.svg);
  }
  .flag-icon-ch.flag-icon-squared,
  .flag-icon-che.flag-icon-squared {
    background-image: url(/images/flags/1x1/ch.svg);
  }
  .flag-icon-ci,
  .flag-icon-civ {
    background-image: url(/images/flags/4x3/ci.svg);
  }
  .flag-icon-ci.flag-icon-squared,
  .flag-icon-civ.flag-icon-squared {
    background-image: url(/images/flags/1x1/ci.svg);
  }
  .flag-icon-ck,
  .flag-icon-cok {
    background-image: url(/images/flags/4x3/ck.svg);
  }
  .flag-icon-ck.flag-icon-squared,
  .flag-icon-cok.flag-icon-squared {
    background-image: url(/images/flags/1x1/ck.svg);
  }
  .flag-icon-cl,
  .flag-icon-chl {
    background-image: url(/images/flags/4x3/cl.svg);
  }
  .flag-icon-cl.flag-icon-squared,
  .flag-icon-chl.flag-icon-squared {
    background-image: url(/images/flags/1x1/cl.svg);
  }
  .flag-icon-cm,
  .flag-icon-cmr {
    background-image: url(/images/flags/4x3/cm.svg);
  }
  .flag-icon-cm.flag-icon-squared,
  .flag-icon-cmr.flag-icon-squared {
    background-image: url(/images/flags/1x1/cm.svg);
  }
  .flag-icon-cn,
  .flag-icon-chn {
    background-image: url(/images/flags/4x3/cn.svg);
  }
  .flag-icon-cn.flag-icon-squared,
  .flag-icon-chn.flag-icon-squared {
    background-image: url(/images/flags/1x1/cn.svg);
  }
  .flag-icon-co,
  .flag-icon-col {
    background-image: url(/images/flags/4x3/co.svg);
  }
  .flag-icon-co.flag-icon-squared,
  .flag-icon-col.flag-icon-squared {
    background-image: url(/images/flags/1x1/co.svg);
  }
  .flag-icon-cr,
  .flag-icon-cri {
    background-image: url(/images/flags/4x3/cr.svg);
  }
  .flag-icon-cr.flag-icon-squared,
  .flag-icon-cri.flag-icon-squared {
    background-image: url(/images/flags/1x1/cr.svg);
  }
  .flag-icon-cu,
  .flag-icon-cub {
    background-image: url(/images/flags/4x3/cu.svg);
  }
  .flag-icon-cu.flag-icon-squared,
  .flag-icon-cub.flag-icon-squared {
    background-image: url(/images/flags/1x1/cu.svg);
  }
  .flag-icon-cv,
  .flag-icon-cpv {
    background-image: url(/images/flags/4x3/cv.svg);
  }
  .flag-icon-cv.flag-icon-squared,
  .flag-icon-cpv.flag-icon-squared {
    background-image: url(/images/flags/1x1/cv.svg);
  }
  .flag-icon-cw,
  .flag-icon-cuw {
    background-image: url(/images/flags/4x3/cw.svg);
  }
  .flag-icon-cw.flag-icon-squared,
  .flag-icon-cuw.flag-icon-squared {
    background-image: url(/images/flags/1x1/cw.svg);
  }
  .flag-icon-cx,
  .flag-icon-cxr {
    background-image: url(/images/flags/4x3/cx.svg);
  }
  .flag-icon-cx.flag-icon-squared,
  .flag-icon-cxr.flag-icon-squared {
    background-image: url(/images/flags/1x1/cx.svg);
  }
  .flag-icon-cy,
  .flag-icon-cyp {
    background-image: url(/images/flags/4x3/cy.svg);
  }
  .flag-icon-cy.flag-icon-squared,
  .flag-icon-cyp.flag-icon-squared {
    background-image: url(/images/flags/1x1/cy.svg);
  }
  .flag-icon-cz,
  .flag-icon-cze {
    background-image: url(/images/flags/4x3/cz.svg);
  }
  .flag-icon-cz.flag-icon-squared,
  .flag-icon-cze.flag-icon-squared {
    background-image: url(/images/flags/1x1/cz.svg);
  }
  .flag-icon-de,
  .flag-icon-deu {
    background-image: url(/images/flags/4x3/de.svg);
  }
  .flag-icon-de.flag-icon-squared,
  .flag-icon-deu.flag-icon-squared {
    background-image: url(/images/flags/1x1/de.svg);
  }
  .flag-icon-dj,
  .flag-icon-dji {
    background-image: url(/images/flags/4x3/dj.svg);
  }
  .flag-icon-dj.flag-icon-squared,
  .flag-icon-dji.flag-icon-squared {
    background-image: url(/images/flags/1x1/dj.svg);
  }
  .flag-icon-dk,
  .flag-icon-dnk {
    background-image: url(/images/flags/4x3/dk.svg);
  }
  .flag-icon-dk.flag-icon-squared,
  .flag-icon-dnk.flag-icon-squared {
    background-image: url(/images/flags/1x1/dk.svg);
  }
  .flag-icon-dm,
  .flag-icon-dma {
    background-image: url(/images/flags/4x3/dm.svg);
  }
  .flag-icon-dm.flag-icon-squared,
  .flag-icon-dma.flag-icon-squared {
    background-image: url(/images/flags/1x1/dm.svg);
  }
  .flag-icon-do,
  .flag-icon-dom {
    background-image: url(/images/flags/4x3/do.svg);
  }
  .flag-icon-do.flag-icon-squared,
  .flag-icon-dom.flag-icon-squared {
    background-image: url(/images/flags/1x1/do.svg);
  }
  .flag-icon-dz,
  .flag-icon-dza {
    background-image: url(/images/flags/4x3/dz.svg);
  }
  .flag-icon-dz.flag-icon-squared,
  .flag-icon-dza.flag-icon-squared {
    background-image: url(/images/flags/1x1/dz.svg);
  }
  .flag-icon-ec,
  .flag-icon-ecu {
    background-image: url(/images/flags/4x3/ec.svg);
  }
  .flag-icon-ec.flag-icon-squared,
  .flag-icon-ecu.flag-icon-squared {
    background-image: url(/images/flags/1x1/ec.svg);
  }
  .flag-icon-ee,
  .flag-icon-est {
    background-image: url(/images/flags/4x3/ee.svg);
  }
  .flag-icon-ee.flag-icon-squared,
  .flag-icon-est.flag-icon-squared {
    background-image: url(/images/flags/1x1/ee.svg);
  }
  .flag-icon-eg,
  .flag-icon-egy {
    background-image: url(/images/flags/4x3/eg.svg);
  }
  .flag-icon-eg.flag-icon-squared,
  .flag-icon-egy.flag-icon-squared {
    background-image: url(/images/flags/1x1/eg.svg);
  }
  .flag-icon-eh,
  .flag-icon-esh {
    background-image: url(/images/flags/4x3/eh.svg);
  }
  .flag-icon-eh.flag-icon-squared,
  .flag-icon-esh.flag-icon-squared {
    background-image: url(/images/flags/1x1/eh.svg);
  }
  .flag-icon-er,
  .flag-icon-eri {
    background-image: url(/images/flags/4x3/er.svg);
  }
  .flag-icon-er.flag-icon-squared,
  .flag-icon-eri.flag-icon-squared {
    background-image: url(/images/flags/1x1/er.svg);
  }
  .flag-icon-es,
  .flag-icon-esp {
    background-image: url(/images/flags/4x3/es.svg);
  }
  .flag-icon-es.flag-icon-squared,
  .flag-icon-esp.flag-icon-squared {
    background-image: url(/images/flags/1x1/es.svg);
  }
  .flag-icon-et,
  .flag-icon-eth {
    background-image: url(/images/flags/4x3/et.svg);
  }
  .flag-icon-et.flag-icon-squared,
  .flag-icon-eth.flag-icon-squared {
    background-image: url(/images/flags/1x1/et.svg);
  }
  .flag-icon-fi,
  .flag-icon-fin {
    background-image: url(/images/flags/4x3/fi.svg);
  }
  .flag-icon-fi.flag-icon-squared,
  .flag-icon-fin.flag-icon-squared {
    background-image: url(/images/flags/1x1/fi.svg);
  }
  .flag-icon-fj,
  .flag-icon-fji {
    background-image: url(/images/flags/4x3/fj.svg);
  }
  .flag-icon-fj.flag-icon-squared,
  .flag-icon-fji.flag-icon-squared {
    background-image: url(/images/flags/1x1/fj.svg);
  }
  .flag-icon-fk,
  .flag-icon-flk {
    background-image: url(/images/flags/4x3/fk.svg);
  }
  .flag-icon-fk.flag-icon-squared,
  .flag-icon-flk.flag-icon-squared {
    background-image: url(/images/flags/1x1/fk.svg);
  }
  .flag-icon-fm,
  .flag-icon-fsm {
    background-image: url(/images/flags/4x3/fm.svg);
  }
  .flag-icon-fm.flag-icon-squared,
  .flag-icon-fsm.flag-icon-squared {
    background-image: url(/images/flags/1x1/fm.svg);
  }
  .flag-icon-fo,
  .flag-icon-fro {
    background-image: url(/images/flags/4x3/fo.svg);
  }
  .flag-icon-fo.flag-icon-squared,
  .flag-icon-fro.flag-icon-squared {
    background-image: url(/images/flags/1x1/fo.svg);
  }
  .flag-icon-fr,
  .flag-icon-fra {
    background-image: url(/images/flags/4x3/fr.svg);
  }
  .flag-icon-fr.flag-icon-squared,
  .flag-icon-fra.flag-icon-squared {
    background-image: url(/images/flags/1x1/fr.svg);
  }
  .flag-icon-ga,
  .flag-icon-gab {
    background-image: url(/images/flags/4x3/ga.svg);
  }
  .flag-icon-ga.flag-icon-squared,
  .flag-icon-gab.flag-icon-squared {
    background-image: url(/images/flags/1x1/ga.svg);
  }
  .flag-icon-gb,
  .flag-icon-gbr {
    background-image: url(/images/flags/4x3/gb.svg);
  }
  .flag-icon-gb.flag-icon-squared,
  .flag-icon-gbr.flag-icon-squared {
    background-image: url(/images/flags/1x1/gb.svg);
  }
  .flag-icon-gd,
  .flag-icon-grd {
    background-image: url(/images/flags/4x3/gd.svg);
  }
  .flag-icon-gd.flag-icon-squared,
  .flag-icon-grd.flag-icon-squared {
    background-image: url(/images/flags/1x1/gd.svg);
  }
  .flag-icon-ge,
  .flag-icon-geo {
    background-image: url(/images/flags/4x3/ge.svg);
  }
  .flag-icon-ge.flag-icon-squared,
  .flag-icon-geo.flag-icon-squared {
    background-image: url(/images/flags/1x1/ge.svg);
  }
  .flag-icon-gf,
  .flag-icon-guf {
    background-image: url(/images/flags/4x3/gf.svg);
  }
  .flag-icon-gf.flag-icon-squared,
  .flag-icon-guf.flag-icon-squared {
    background-image: url(/images/flags/1x1/gf.svg);
  }
  .flag-icon-gg,
  .flag-icon-ggy {
    background-image: url(/images/flags/4x3/gg.svg);
  }
  .flag-icon-gg.flag-icon-squared,
  .flag-icon-ggy.flag-icon-squared {
    background-image: url(/images/flags/1x1/gg.svg);
  }
  .flag-icon-gh,
  .flag-icon-gha {
    background-image: url(/images/flags/4x3/gh.svg);
  }
  .flag-icon-gh.flag-icon-squared,
  .flag-icon-gha.flag-icon-squared {
    background-image: url(/images/flags/1x1/gh.svg);
  }
  .flag-icon-gi,
  .flag-icon-gib {
    background-image: url(/images/flags/4x3/gi.svg);
  }
  .flag-icon-gi.flag-icon-squared,
  .flag-icon-gib.flag-icon-squared {
    background-image: url(/images/flags/1x1/gi.svg);
  }
  .flag-icon-gl,
  .flag-icon-grl {
    background-image: url(/images/flags/4x3/gl.svg);
  }
  .flag-icon-gl.flag-icon-squared,
  .flag-icon-grl.flag-icon-squared {
    background-image: url(/images/flags/1x1/gl.svg);
  }
  .flag-icon-gm,
  .flag-icon-gmb {
    background-image: url(/images/flags/4x3/gm.svg);
  }
  .flag-icon-gm.flag-icon-squared,
  .flag-icon-gmb.flag-icon-squared {
    background-image: url(/images/flags/1x1/gm.svg);
  }
  .flag-icon-gn,
  .flag-icon-gin {
    background-image: url(/images/flags/4x3/gn.svg);
  }
  .flag-icon-gn.flag-icon-squared,
  .flag-icon-gin.flag-icon-squared {
    background-image: url(/images/flags/1x1/gn.svg);
  }
  .flag-icon-gp,
  .flag-icon-glp {
    background-image: url(/images/flags/4x3/gp.svg);
  }
  .flag-icon-gp.flag-icon-squared,
  .flag-icon-glp.flag-icon-squared {
    background-image: url(/images/flags/1x1/gp.svg);
  }
  .flag-icon-gq,
  .flag-icon-gnq {
    background-image: url(/images/flags/4x3/gq.svg);
  }
  .flag-icon-gq.flag-icon-squared,
  .flag-icon-gnq.flag-icon-squared {
    background-image: url(/images/flags/1x1/gq.svg);
  }
  .flag-icon-gr,
  .flag-icon-grc {
    background-image: url(/images/flags/4x3/gr.svg);
  }
  .flag-icon-gr.flag-icon-squared,
  .flag-icon-grc.flag-icon-squared {
    background-image: url(/images/flags/1x1/gr.svg);
  }
  .flag-icon-gs,
  .flag-icon-sgs {
    background-image: url(/images/flags/4x3/gs.svg);
  }
  .flag-icon-gs.flag-icon-squared,
  .flag-icon-sgs.flag-icon-squared {
    background-image: url(/images/flags/1x1/gs.svg);
  }
  .flag-icon-gt,
  .flag-icon-gtm {
    background-image: url(/images/flags/4x3/gt.svg);
  }
  .flag-icon-gt.flag-icon-squared,
  .flag-icon-gtm.flag-icon-squared {
    background-image: url(/images/flags/1x1/gt.svg);
  }
  .flag-icon-gu,
  .flag-icon-gum {
    background-image: url(/images/flags/4x3/gu.svg);
  }
  .flag-icon-gu.flag-icon-squared,
  .flag-icon-gum.flag-icon-squared {
    background-image: url(/images/flags/1x1/gu.svg);
  }
  .flag-icon-gw,
  .flag-icon-gnb {
    background-image: url(/images/flags/4x3/gw.svg);
  }
  .flag-icon-gw.flag-icon-squared,
  .flag-icon-gnb.flag-icon-squared {
    background-image: url(/images/flags/1x1/gw.svg);
  }
  .flag-icon-gy,
  .flag-icon-guy {
    background-image: url(/images/flags/4x3/gy.svg);
  }
  .flag-icon-gy.flag-icon-squared,
  .flag-icon-guy.flag-icon-squared {
    background-image: url(/images/flags/1x1/gy.svg);
  }
  .flag-icon-hk,
  .flag-icon-hkg {
    background-image: url(/images/flags/4x3/hk.svg);
  }
  .flag-icon-hk.flag-icon-squared,
  .flag-icon-hkg.flag-icon-squared {
    background-image: url(/images/flags/1x1/hk.svg);
  }
  .flag-icon-hm,
  .flag-icon-hmd {
    background-image: url(/images/flags/4x3/hm.svg);
  }
  .flag-icon-hm.flag-icon-squared,
  .flag-icon-hmd.flag-icon-squared {
    background-image: url(/images/flags/1x1/hm.svg);
  }
  .flag-icon-hn,
  .flag-icon-hnd {
    background-image: url(/images/flags/4x3/hn.svg);
  }
  .flag-icon-hn.flag-icon-squared,
  .flag-icon-hnd.flag-icon-squared {
    background-image: url(/images/flags/1x1/hn.svg);
  }
  .flag-icon-hr,
  .flag-icon-hrv {
    background-image: url(/images/flags/4x3/hr.svg);
  }
  .flag-icon-hr.flag-icon-squared,
  .flag-icon-hrv.flag-icon-squared {
    background-image: url(/images/flags/1x1/hr.svg);
  }
  .flag-icon-ht,
  .flag-icon-hti {
    background-image: url(/images/flags/4x3/ht.svg);
  }
  .flag-icon-ht.flag-icon-squared,
  .flag-icon-hti.flag-icon-squared {
    background-image: url(/images/flags/1x1/ht.svg);
  }
  .flag-icon-hu,
  .flag-icon-hun {
    background-image: url(/images/flags/4x3/hu.svg);
  }
  .flag-icon-hu.flag-icon-squared,
  .flag-icon-hun.flag-icon-squared {
    background-image: url(/images/flags/1x1/hu.svg);
  }
  .flag-icon-id,
  .flag-icon-idn {
    background-image: url(/images/flags/4x3/id.svg);
  }
  .flag-icon-id.flag-icon-squared,
  .flag-icon-idn.flag-icon-squared {
    background-image: url(/images/flags/1x1/id.svg);
  }
  .flag-icon-ie,
  .flag-icon-irl {
    background-image: url(/images/flags/4x3/ie.svg);
  }
  .flag-icon-ie.flag-icon-squared,
  .flag-icon-irl.flag-icon-squared {
    background-image: url(/images/flags/1x1/ie.svg);
  }
  .flag-icon-il,
  .flag-icon-isr {
    background-image: url(/images/flags/4x3/il.svg);
  }
  .flag-icon-il.flag-icon-squared,
  .flag-icon-isr.flag-icon-squared {
    background-image: url(/images/flags/1x1/il.svg);
  }
  .flag-icon-im,
  .flag-icon-imn {
    background-image: url(/images/flags/4x3/im.svg);
  }
  .flag-icon-im.flag-icon-squared,
  .flag-icon-imn.flag-icon-squared {
    background-image: url(/images/flags/1x1/im.svg);
  }
  .flag-icon-in,
  .flag-icon-ind {
    background-image: url(/images/flags/4x3/in.svg);
  }
  .flag-icon-in.flag-icon-squared,
  .flag-icon-ind.flag-icon-squared {
    background-image: url(/images/flags/1x1/in.svg);
  }
  .flag-icon-io,
  .flag-icon-iot {
    background-image: url(/images/flags/4x3/io.svg);
  }
  .flag-icon-io.flag-icon-squared,
  .flag-icon-iot.flag-icon-squared {
    background-image: url(/images/flags/1x1/io.svg);
  }
  .flag-icon-iq,
  .flag-icon-irq {
    background-image: url(/images/flags/4x3/iq.svg);
  }
  .flag-icon-iq.flag-icon-squared,
  .flag-icon-irq.flag-icon-squared {
    background-image: url(/images/flags/1x1/iq.svg);
  }
  .flag-icon-ir,
  .flag-icon-irn {
    background-image: url(/images/flags/4x3/ir.svg);
  }
  .flag-icon-ir.flag-icon-squared,
  .flag-icon-irn.flag-icon-squared {
    background-image: url(/images/flags/1x1/ir.svg);
  }
  .flag-icon-is,
  .flag-icon-isl {
    background-image: url(/images/flags/4x3/is.svg);
  }
  .flag-icon-is.flag-icon-squared,
  .flag-icon-isl.flag-icon-squared {
    background-image: url(/images/flags/1x1/is.svg);
  }
  .flag-icon-it,
  .flag-icon-ita {
    background-image: url(/images/flags/4x3/it.svg);
  }
  .flag-icon-it.flag-icon-squared,
  .flag-icon-ita.flag-icon-squared {
    background-image: url(/images/flags/1x1/it.svg);
  }
  .flag-icon-je,
  .flag-icon-jey {
    background-image: url(/images/flags/4x3/je.svg);
  }
  .flag-icon-je.flag-icon-squared,
  .flag-icon-jey.flag-icon-squared {
    background-image: url(/images/flags/1x1/je.svg);
  }
  .flag-icon-jm,
  .flag-icon-jam {
    background-image: url(/images/flags/4x3/jm.svg);
  }
  .flag-icon-jm.flag-icon-squared,
  .flag-icon-jam.flag-icon-squared {
    background-image: url(/images/flags/1x1/jm.svg);
  }
  .flag-icon-jo,
  .flag-icon-jor {
    background-image: url(/images/flags/4x3/jo.svg);
  }
  .flag-icon-jo.flag-icon-squared,
  .flag-icon-jor.flag-icon-squared {
    background-image: url(/images/flags/1x1/jo.svg);
  }
  .flag-icon-jp,
  .flag-icon-jpn {
    background-image: url(/images/flags/4x3/jp.svg);
  }
  .flag-icon-jp.flag-icon-squared,
  .flag-icon-jpn.flag-icon-squared {
    background-image: url(/images/flags/1x1/jp.svg);
  }
  .flag-icon-ke,
  .flag-icon-ken {
    background-image: url(/images/flags/4x3/ke.svg);
  }
  .flag-icon-ke.flag-icon-squared,
  .flag-icon-ken.flag-icon-squared {
    background-image: url(/images/flags/1x1/ke.svg);
  }
  .flag-icon-kg,
  .flag-icon-kgz {
    background-image: url(/images/flags/4x3/kg.svg);
  }
  .flag-icon-kg.flag-icon-squared,
  .flag-icon-kgz.flag-icon-squared {
    background-image: url(/images/flags/1x1/kg.svg);
  }
  .flag-icon-kh,
  .flag-icon-khm {
    background-image: url(/images/flags/4x3/kh.svg);
  }
  .flag-icon-kh.flag-icon-squared,
  .flag-icon-khm.flag-icon-squared {
    background-image: url(/images/flags/1x1/kh.svg);
  }
  .flag-icon-ki,
  .flag-icon-kir {
    background-image: url(/images/flags/4x3/ki.svg);
  }
  .flag-icon-ki.flag-icon-squared,
  .flag-icon-kir.flag-icon-squared {
    background-image: url(/images/flags/1x1/ki.svg);
  }
  .flag-icon-km,
  .flag-icon-com {
    background-image: url(/images/flags/4x3/km.svg);
  }
  .flag-icon-km.flag-icon-squared,
  .flag-icon-com.flag-icon-squared {
    background-image: url(/images/flags/1x1/km.svg);
  }
  .flag-icon-kn,
  .flag-icon-kna {
    background-image: url(/images/flags/4x3/kn.svg);
  }
  .flag-icon-kn.flag-icon-squared,
  .flag-icon-kna.flag-icon-squared {
    background-image: url(/images/flags/1x1/kn.svg);
  }
  .flag-icon-kp,
  .flag-icon-prk {
    background-image: url(/images/flags/4x3/kp.svg);
  }
  .flag-icon-kp.flag-icon-squared,
  .flag-icon-prk.flag-icon-squared {
    background-image: url(/images/flags/1x1/kp.svg);
  }
  .flag-icon-kr,
  .flag-icon-kor {
    background-image: url(/images/flags/4x3/kr.svg);
  }
  .flag-icon-kr.flag-icon-squared,
  .flag-icon-kor.flag-icon-squared {
    background-image: url(/images/flags/1x1/kr.svg);
  }
  .flag-icon-kw,
  .flag-icon-kwt {
    background-image: url(/images/flags/4x3/kw.svg);
  }
  .flag-icon-kw.flag-icon-squared,
  .flag-icon-kwt.flag-icon-squared {
    background-image: url(/images/flags/1x1/kw.svg);
  }
  .flag-icon-ky,
  .flag-icon-cym {
    background-image: url(/images/flags/4x3/ky.svg);
  }
  .flag-icon-ky.flag-icon-squared,
  .flag-icon-cym.flag-icon-squared {
    background-image: url(/images/flags/1x1/ky.svg);
  }
  .flag-icon-kz,
  .flag-icon-kaz {
    background-image: url(/images/flags/4x3/kz.svg);
  }
  .flag-icon-kz.flag-icon-squared,
  .flag-icon-kaz.flag-icon-squared {
    background-image: url(/images/flags/1x1/kz.svg);
  }
  .flag-icon-la,
  .flag-icon-lao {
    background-image: url(/images/flags/4x3/la.svg);
  }
  .flag-icon-la.flag-icon-squared,
  .flag-icon-lao.flag-icon-squared {
    background-image: url(/images/flags/1x1/la.svg);
  }
  .flag-icon-lb,
  .flag-icon-lbn {
    background-image: url(/images/flags/4x3/lb.svg);
  }
  .flag-icon-lb.flag-icon-squared,
  .flag-icon-lbn.flag-icon-squared {
    background-image: url(/images/flags/1x1/lb.svg);
  }
  .flag-icon-lc,
  .flag-icon-lca {
    background-image: url(/images/flags/4x3/lc.svg);
  }
  .flag-icon-lc.flag-icon-squared,
  .flag-icon-lca.flag-icon-squared {
    background-image: url(/images/flags/1x1/lc.svg);
  }
  .flag-icon-li,
  .flag-icon-lie {
    background-image: url(/images/flags/4x3/li.svg);
  }
  .flag-icon-li.flag-icon-squared,
  .flag-icon-lie.flag-icon-squared {
    background-image: url(/images/flags/1x1/li.svg);
  }
  .flag-icon-lk,
  .flag-icon-lka {
    background-image: url(/images/flags/4x3/lk.svg);
  }
  .flag-icon-lk.flag-icon-squared,
  .flag-icon-lka.flag-icon-squared {
    background-image: url(/images/flags/1x1/lk.svg);
  }
  .flag-icon-lr,
  .flag-icon-lbr {
    background-image: url(/images/flags/4x3/lr.svg);
  }
  .flag-icon-lr.flag-icon-squared,
  .flag-icon-lbr.flag-icon-squared {
    background-image: url(/images/flags/1x1/lr.svg);
  }
  .flag-icon-ls,
  .flag-icon-lso {
    background-image: url(/images/flags/4x3/ls.svg);
  }
  .flag-icon-ls.flag-icon-squared,
  .flag-icon-lso.flag-icon-squared {
    background-image: url(/images/flags/1x1/ls.svg);
  }
  .flag-icon-lt,
  .flag-icon-ltu {
    background-image: url(/images/flags/4x3/lt.svg);
  }
  .flag-icon-lt.flag-icon-squared,
  .flag-icon-ltu.flag-icon-squared {
    background-image: url(/images/flags/1x1/lt.svg);
  }
  .flag-icon-lu,
  .flag-icon-lux {
    background-image: url(/images/flags/4x3/lu.svg);
  }
  .flag-icon-lu.flag-icon-squared,
  .flag-icon-lux.flag-icon-squared {
    background-image: url(/images/flags/1x1/lu.svg);
  }
  .flag-icon-lv,
  .flag-icon-lva {
    background-image: url(/images/flags/4x3/lv.svg);
  }
  .flag-icon-lv.flag-icon-squared,
  .flag-icon-lva.flag-icon-squared {
    background-image: url(/images/flags/1x1/lv.svg);
  }
  .flag-icon-ly,
  .flag-icon-lby {
    background-image: url(/images/flags/4x3/ly.svg);
  }
  .flag-icon-ly.flag-icon-squared,
  .flag-icon-lby.flag-icon-squared {
    background-image: url(/images/flags/1x1/ly.svg);
  }
  .flag-icon-ma,
  .flag-icon-mar {
    background-image: url(/images/flags/4x3/ma.svg);
  }
  .flag-icon-ma.flag-icon-squared,
  .flag-icon-mar.flag-icon-squared {
    background-image: url(/images/flags/1x1/ma.svg);
  }
  .flag-icon-mc,
  .flag-icon-mco {
    background-image: url(/images/flags/4x3/mc.svg);
  }
  .flag-icon-mc.flag-icon-squared,
  .flag-icon-mco.flag-icon-squared {
    background-image: url(/images/flags/1x1/mc.svg);
  }
  .flag-icon-md,
  .flag-icon-mda {
    background-image: url(/images/flags/4x3/md.svg);
  }
  .flag-icon-md.flag-icon-squared,
  .flag-icon-mda.flag-icon-squared {
    background-image: url(/images/flags/1x1/md.svg);
  }
  .flag-icon-me,
  .flag-icon-mne {
    background-image: url(/images/flags/4x3/me.svg);
  }
  .flag-icon-me.flag-icon-squared,
  .flag-icon-mne.flag-icon-squared {
    background-image: url(/images/flags/1x1/me.svg);
  }
  .flag-icon-mf,
  .flag-icon-maf {
    background-image: url(/images/flags/4x3/mf.svg);
  }
  .flag-icon-mf.flag-icon-squared,
  .flag-icon-maf.flag-icon-squared {
    background-image: url(/images/flags/1x1/mf.svg);
  }
  .flag-icon-mg,
  .flag-icon-mdg {
    background-image: url(/images/flags/4x3/mg.svg);
  }
  .flag-icon-mg.flag-icon-squared,
  .flag-icon-mdg.flag-icon-squared {
    background-image: url(/images/flags/1x1/mg.svg);
  }
  .flag-icon-mh,
  .flag-icon-mhl {
    background-image: url(/images/flags/4x3/mh.svg);
  }
  .flag-icon-mh.flag-icon-squared,
  .flag-icon-mhl.flag-icon-squared {
    background-image: url(/images/flags/1x1/mh.svg);
  }
  .flag-icon-mk,
  .flag-icon-mkd {
    background-image: url(/images/flags/4x3/mk.svg);
  }
  .flag-icon-mk.flag-icon-squared,
  .flag-icon-mkd.flag-icon-squared {
    background-image: url(/images/flags/1x1/mk.svg);
  }
  .flag-icon-ml,
  .flag-icon-mli {
    background-image: url(/images/flags/4x3/ml.svg);
  }
  .flag-icon-ml.flag-icon-squared,
  .flag-icon-mli.flag-icon-squared {
    background-image: url(/images/flags/1x1/ml.svg);
  }
  .flag-icon-mm,
  .flag-icon-mmr {
    background-image: url(/images/flags/4x3/mm.svg);
  }
  .flag-icon-mm.flag-icon-squared,
  .flag-icon-mmr.flag-icon-squared {
    background-image: url(/images/flags/1x1/mm.svg);
  }
  .flag-icon-mn,
  .flag-icon-mng {
    background-image: url(/images/flags/4x3/mn.svg);
  }
  .flag-icon-mn.flag-icon-squared,
  .flag-icon-mng.flag-icon-squared {
    background-image: url(/images/flags/1x1/mn.svg);
  }
  .flag-icon-mo,
  .flag-icon-mac {
    background-image: url(/images/flags/4x3/mo.svg);
  }
  .flag-icon-mo.flag-icon-squared,
  .flag-icon-mac.flag-icon-squared {
    background-image: url(/images/flags/1x1/mo.svg);
  }
  .flag-icon-mp,
  .flag-icon-mnp {
    background-image: url(/images/flags/4x3/mp.svg);
  }
  .flag-icon-mp.flag-icon-squared,
  .flag-icon-mnp.flag-icon-squared {
    background-image: url(/images/flags/1x1/mp.svg);
  }
  .flag-icon-mq,
  .flag-icon-mtq {
    background-image: url(/images/flags/4x3/mq.svg);
  }
  .flag-icon-mq.flag-icon-squared,
  .flag-icon-mtq.flag-icon-squared {
    background-image: url(/images/flags/1x1/mq.svg);
  }
  .flag-icon-mr,
  .flag-icon-mrt {
    background-image: url(/images/flags/4x3/mr.svg);
  }
  .flag-icon-mr.flag-icon-squared,
  .flag-icon-mrt.flag-icon-squared {
    background-image: url(/images/flags/1x1/mr.svg);
  }
  .flag-icon-ms,
  .flag-icon-msr {
    background-image: url(/images/flags/4x3/ms.svg);
  }
  .flag-icon-ms.flag-icon-squared,
  .flag-icon-msr.flag-icon-squared {
    background-image: url(/images/flags/1x1/ms.svg);
  }
  .flag-icon-mt,
  .flag-icon-mlt {
    background-image: url(/images/flags/4x3/mt.svg);
  }
  .flag-icon-mt.flag-icon-squared,
  .flag-icon-mlt.flag-icon-squared {
    background-image: url(/images/flags/1x1/mt.svg);
  }
  .flag-icon-mu,
  .flag-icon-mus {
    background-image: url(/images/flags/4x3/mu.svg);
  }
  .flag-icon-mu.flag-icon-squared,
  .flag-icon-mus.flag-icon-squared {
    background-image: url(/images/flags/1x1/mu.svg);
  }
  .flag-icon-mv,
  .flag-icon-mdv {
    background-image: url(/images/flags/4x3/mv.svg);
  }
  .flag-icon-mv.flag-icon-squared,
  .flag-icon-mdv.flag-icon-squared {
    background-image: url(/images/flags/1x1/mv.svg);
  }
  .flag-icon-mw,
  .flag-icon-mwi {
    background-image: url(/images/flags/4x3/mw.svg);
  }
  .flag-icon-mw.flag-icon-squared,
  .flag-icon-mwi.flag-icon-squared {
    background-image: url(/images/flags/1x1/mw.svg);
  }
  .flag-icon-mx,
  .flag-icon-mex {
    background-image: url(/images/flags/4x3/mx.svg);
  }
  .flag-icon-mx.flag-icon-squared,
  .flag-icon-mex.flag-icon-squared {
    background-image: url(/images/flags/1x1/mx.svg);
  }
  .flag-icon-my,
  .flag-icon-mys {
    background-image: url(/images/flags/4x3/my.svg);
  }
  .flag-icon-my.flag-icon-squared,
  .flag-icon-mys.flag-icon-squared {
    background-image: url(/images/flags/1x1/my.svg);
  }
  .flag-icon-mz,
  .flag-icon-moz {
    background-image: url(/images/flags/4x3/mz.svg);
  }
  .flag-icon-mz.flag-icon-squared,
  .flag-icon-moz.flag-icon-squared {
    background-image: url(/images/flags/1x1/mz.svg);
  }
  .flag-icon-na,
  .flag-icon-nam {
    background-image: url(/images/flags/4x3/na.svg);
  }
  .flag-icon-na.flag-icon-squared,
  .flag-icon-nam.flag-icon-squared {
    background-image: url(/images/flags/1x1/na.svg);
  }
  .flag-icon-nc,
  .flag-icon-ncl {
    background-image: url(/images/flags/4x3/nc.svg);
  }
  .flag-icon-nc.flag-icon-squared,
  .flag-icon-ncl.flag-icon-squared {
    background-image: url(/images/flags/1x1/nc.svg);
  }
  .flag-icon-ne,
  .flag-icon-ner {
    background-image: url(/images/flags/4x3/ne.svg);
  }
  .flag-icon-ne.flag-icon-squared,
  .flag-icon-ner.flag-icon-squared {
    background-image: url(/images/flags/1x1/ne.svg);
  }
  .flag-icon-nf,
  .flag-icon-nfk {
    background-image: url(/images/flags/4x3/nf.svg);
  }
  .flag-icon-nf.flag-icon-squared,
  .flag-icon-nfk.flag-icon-squared {
    background-image: url(/images/flags/1x1/nf.svg);
  }
  .flag-icon-ng,
  .flag-icon-nga {
    background-image: url(/images/flags/4x3/ng.svg);
  }
  .flag-icon-ng.flag-icon-squared,
  .flag-icon-nga.flag-icon-squared {
    background-image: url(/images/flags/1x1/ng.svg);
  }
  .flag-icon-ni,
  .flag-icon-nic {
    background-image: url(/images/flags/4x3/ni.svg);
  }
  .flag-icon-ni.flag-icon-squared,
  .flag-icon-nic.flag-icon-squared {
    background-image: url(/images/flags/1x1/ni.svg);
  }
  .flag-icon-nl,
  .flag-icon-nld {
    background-image: url(/images/flags/4x3/nl.svg);
  }
  .flag-icon-nl.flag-icon-squared,
  .flag-icon-nld.flag-icon-squared {
    background-image: url(/images/flags/1x1/nl.svg);
  }
  .flag-icon-no,
  .flag-icon-nor {
    background-image: url(/images/flags/4x3/no.svg);
  }
  .flag-icon-no.flag-icon-squared,
  .flag-icon-nor.flag-icon-squared {
    background-image: url(/images/flags/1x1/no.svg);
  }
  .flag-icon-np,
  .flag-icon-npl {
    background-image: url(/images/flags/4x3/np.svg);
  }
  .flag-icon-np.flag-icon-squared,
  .flag-icon-npl.flag-icon-squared {
    background-image: url(/images/flags/1x1/np.svg);
  }
  .flag-icon-nr,
  .flag-icon-nru {
    background-image: url(/images/flags/4x3/nr.svg);
  }
  .flag-icon-nr.flag-icon-squared,
  .flag-icon-nru.flag-icon-squared {
    background-image: url(/images/flags/1x1/nr.svg);
  }
  .flag-icon-nu,
  .flag-icon-niu {
    background-image: url(/images/flags/4x3/nu.svg);
  }
  .flag-icon-nu.flag-icon-squared,
  .flag-icon-niu.flag-icon-squared {
    background-image: url(/images/flags/1x1/nu.svg);
  }
  .flag-icon-nz,
  .flag-icon-nzl {
    background-image: url(/images/flags/4x3/nz.svg);
  }
  .flag-icon-nz.flag-icon-squared,
  .flag-icon-nzl.flag-icon-squared {
    background-image: url(/images/flags/1x1/nz.svg);
  }
  .flag-icon-om,
  .flag-icon-omn {
    background-image: url(/images/flags/4x3/om.svg);
  }
  .flag-icon-om.flag-icon-squared,
  .flag-icon-omn.flag-icon-squared {
    background-image: url(/images/flags/1x1/om.svg);
  }
  .flag-icon-pa,
  .flag-icon-pan {
    background-image: url(/images/flags/4x3/pa.svg);
  }
  .flag-icon-pa.flag-icon-squared,
  .flag-icon-pan.flag-icon-squared {
    background-image: url(/images/flags/1x1/pa.svg);
  }
  .flag-icon-pe,
  .flag-icon-per {
    background-image: url(/images/flags/4x3/pe.svg);
  }
  .flag-icon-pe.flag-icon-squared,
  .flag-icon-per.flag-icon-squared {
    background-image: url(/images/flags/1x1/pe.svg);
  }
  .flag-icon-pf,
  .flag-icon-pyf {
    background-image: url(/images/flags/4x3/pf.svg);
  }
  .flag-icon-pf.flag-icon-squared,
  .flag-icon-pyf.flag-icon-squared {
    background-image: url(/images/flags/1x1/pf.svg);
  }
  .flag-icon-pg,
  .flag-icon-png {
    background-image: url(/images/flags/4x3/pg.svg);
  }
  .flag-icon-pg.flag-icon-squared,
  .flag-icon-png.flag-icon-squared {
    background-image: url(/images/flags/1x1/pg.svg);
  }
  .flag-icon-ph,
  .flag-icon-phl {
    background-image: url(/images/flags/4x3/ph.svg);
  }
  .flag-icon-ph.flag-icon-squared,
  .flag-icon-phl.flag-icon-squared {
    background-image: url(/images/flags/1x1/ph.svg);
  }
  .flag-icon-pk,
  .flag-icon-pak {
    background-image: url(/images/flags/4x3/pk.svg);
  }
  .flag-icon-pk.flag-icon-squared,
  .flag-icon-pak.flag-icon-squared {
    background-image: url(/images/flags/1x1/pk.svg);
  }
  .flag-icon-pl,
  .flag-icon-pol {
    background-image: url(/images/flags/4x3/pl.svg);
  }
  .flag-icon-pl.flag-icon-squared,
  .flag-icon-pol.flag-icon-squared {
    background-image: url(/images/flags/1x1/pl.svg);
  }
  .flag-icon-pm,
  .flag-icon-spm {
    background-image: url(/images/flags/4x3/pm.svg);
  }
  .flag-icon-pm.flag-icon-squared,
  .flag-icon-spm.flag-icon-squared {
    background-image: url(/images/flags/1x1/pm.svg);
  }
  .flag-icon-pn,
  .flag-icon-pcn {
    background-image: url(/images/flags/4x3/pn.svg);
  }
  .flag-icon-pn.flag-icon-squared,
  .flag-icon-pcn.flag-icon-squared {
    background-image: url(/images/flags/1x1/pn.svg);
  }
  .flag-icon-pr,
  .flag-icon-pri {
    background-image: url(/images/flags/4x3/pr.svg);
  }
  .flag-icon-pr.flag-icon-squared,
  .flag-icon-pri.flag-icon-squared {
    background-image: url(/images/flags/1x1/pr.svg);
  }
  .flag-icon-ps,
  .flag-icon-pse {
    background-image: url(/images/flags/4x3/ps.svg);
  }
  .flag-icon-ps.flag-icon-squared,
  .flag-icon-pse.flag-icon-squared {
    background-image: url(/images/flags/1x1/ps.svg);
  }
  .flag-icon-pt,
  .flag-icon-prt {
    background-image: url(/images/flags/4x3/pt.svg);
  }
  .flag-icon-pt.flag-icon-squared,
  .flag-icon-prt.flag-icon-squared {
    background-image: url(/images/flags/1x1/pt.svg);
  }
  .flag-icon-pw,
  .flag-icon-plw {
    background-image: url(/images/flags/4x3/pw.svg);
  }
  .flag-icon-pw.flag-icon-squared,
  .flag-icon-plw.flag-icon-squared {
    background-image: url(/images/flags/1x1/pw.svg);
  }
  .flag-icon-py,
  .flag-icon-pry {
    background-image: url(/images/flags/4x3/py.svg);
  }
  .flag-icon-py.flag-icon-squared,
  .flag-icon-pry.flag-icon-squared {
    background-image: url(/images/flags/1x1/py.svg);
  }
  .flag-icon-qa,
  .flag-icon-qat {
    background-image: url(/images/flags/4x3/qa.svg);
  }
  .flag-icon-qa.flag-icon-squared,
  .flag-icon-qat.flag-icon-squared {
    background-image: url(/images/flags/1x1/qa.svg);
  }
  .flag-icon-re,
  .flag-icon-reu {
    background-image: url(/images/flags/4x3/re.svg);
  }
  .flag-icon-re.flag-icon-squared,
  .flag-icon-reu.flag-icon-squared {
    background-image: url(/images/flags/1x1/re.svg);
  }
  .flag-icon-ro,
  .flag-icon-rou {
    background-image: url(/images/flags/4x3/ro.svg);
  }
  .flag-icon-ro.flag-icon-squared,
  .flag-icon-rou.flag-icon-squared {
    background-image: url(/images/flags/1x1/ro.svg);
  }
  .flag-icon-rs,
  .flag-icon-srb {
    background-image: url(/images/flags/4x3/rs.svg);
  }
  .flag-icon-rs.flag-icon-squared,
  .flag-icon-srb.flag-icon-squared {
    background-image: url(/images/flags/1x1/rs.svg);
  }
  .flag-icon-ru,
  .flag-icon-rus {
    background-image: url(/images/flags/4x3/ru.svg);
  }
  .flag-icon-ru.flag-icon-squared,
  .flag-icon-rus.flag-icon-squared {
    background-image: url(/images/flags/1x1/ru.svg);
  }
  .flag-icon-rw,
  .flag-icon-rwa {
    background-image: url(/images/flags/4x3/rw.svg);
  }
  .flag-icon-rw.flag-icon-squared,
  .flag-icon-rwa.flag-icon-squared {
    background-image: url(/images/flags/1x1/rw.svg);
  }
  .flag-icon-sa,
  .flag-icon-sau {
    background-image: url(/images/flags/4x3/sa.svg);
  }
  .flag-icon-sa.flag-icon-squared,
  .flag-icon-sau.flag-icon-squared {
    background-image: url(/images/flags/1x1/sa.svg);
  }
  .flag-icon-sb,
  .flag-icon-slb {
    background-image: url(/images/flags/4x3/sb.svg);
  }
  .flag-icon-sb.flag-icon-squared,
  .flag-icon-slb.flag-icon-squared {
    background-image: url(/images/flags/1x1/sb.svg);
  }
  .flag-icon-sc,
  .flag-icon-syc {
    background-image: url(/images/flags/4x3/sc.svg);
  }
  .flag-icon-sc.flag-icon-squared,
  .flag-icon-syc.flag-icon-squared {
    background-image: url(/images/flags/1x1/sc.svg);
  }
  .flag-icon-sd,
  .flag-icon-sdn {
    background-image: url(/images/flags/4x3/sd.svg);
  }
  .flag-icon-sd.flag-icon-squared,
  .flag-icon-sdn.flag-icon-squared {
    background-image: url(/images/flags/1x1/sd.svg);
  }
  .flag-icon-se,
  .flag-icon-swe {
    background-image: url(/images/flags/4x3/se.svg);
  }
  .flag-icon-se.flag-icon-squared,
  .flag-icon-swe.flag-icon-squared {
    background-image: url(/images/flags/1x1/se.svg);
  }
  .flag-icon-sg,
  .flag-icon-sgp {
    background-image: url(/images/flags/4x3/sg.svg);
  }
  .flag-icon-sg.flag-icon-squared,
  .flag-icon-sgp.flag-icon-squared {
    background-image: url(/images/flags/1x1/sg.svg);
  }
  .flag-icon-sh,
  .flag-icon-shn {
    background-image: url(/images/flags/4x3/sh.svg);
  }
  .flag-icon-sh.flag-icon-squared,
  .flag-icon-shn.flag-icon-squared {
    background-image: url(/images/flags/1x1/sh.svg);
  }
  .flag-icon-si,
  .flag-icon-svn {
    background-image: url(/images/flags/4x3/si.svg);
  }
  .flag-icon-si.flag-icon-squared,
  .flag-icon-svn.flag-icon-squared {
    background-image: url(/images/flags/1x1/si.svg);
  }
  .flag-icon-sj,
  .flag-icon-sjm {
    background-image: url(/images/flags/4x3/sj.svg);
  }
  .flag-icon-sj.flag-icon-squared,
  .flag-icon-sjm.flag-icon-squared {
    background-image: url(/images/flags/1x1/sj.svg);
  }
  .flag-icon-sk,
  .flag-icon-svk {
    background-image: url(/images/flags/4x3/sk.svg);
  }
  .flag-icon-sk.flag-icon-squared,
  .flag-icon-svk.flag-icon-squared {
    background-image: url(/images/flags/1x1/sk.svg);
  }
  .flag-icon-sl,
  .flag-icon-sle {
    background-image: url(/images/flags/4x3/sl.svg);
  }
  .flag-icon-sl.flag-icon-squared,
  .flag-icon-sle.flag-icon-squared {
    background-image: url(/images/flags/1x1/sl.svg);
  }
  .flag-icon-sm,
  .flag-icon-smr {
    background-image: url(/images/flags/4x3/sm.svg);
  }
  .flag-icon-sm.flag-icon-squared,
  .flag-icon-smr.flag-icon-squared {
    background-image: url(/images/flags/1x1/sm.svg);
  }
  .flag-icon-sn,
  .flag-icon-sen {
    background-image: url(/images/flags/4x3/sn.svg);
  }
  .flag-icon-sn.flag-icon-squared,
  .flag-icon-sen.flag-icon-squared {
    background-image: url(/images/flags/1x1/sn.svg);
  }
  .flag-icon-so,
  .flag-icon-som {
    background-image: url(/images/flags/4x3/so.svg);
  }
  .flag-icon-so.flag-icon-squared,
  .flag-icon-som.flag-icon-squared {
    background-image: url(/images/flags/1x1/so.svg);
  }
  .flag-icon-sr,
  .flag-icon-sur {
    background-image: url(/images/flags/4x3/sr.svg);
  }
  .flag-icon-sr.flag-icon-squared,
  .flag-icon-sur.flag-icon-squared {
    background-image: url(/images/flags/1x1/sr.svg);
  }
  .flag-icon-ss,
  .flag-icon-ssd {
    background-image: url(/images/flags/4x3/ss.svg);
  }
  .flag-icon-ss.flag-icon-squared,
  .flag-icon-ssd.flag-icon-squared {
    background-image: url(/images/flags/1x1/ss.svg);
  }
  .flag-icon-st,
  .flag-icon-stp {
    background-image: url(/images/flags/4x3/st.svg);
  }
  .flag-icon-st.flag-icon-squared,
  .flag-icon-stp.flag-icon-squared {
    background-image: url(/images/flags/1x1/st.svg);
  }
  .flag-icon-sv,
  .flag-icon-slv {
    background-image: url(/images/flags/4x3/sv.svg);
  }
  .flag-icon-sv.flag-icon-squared,
  .flag-icon-slv.flag-icon-squared {
    background-image: url(/images/flags/1x1/sv.svg);
  }
  .flag-icon-sx,
  .flag-icon-sxm {
    background-image: url(/images/flags/4x3/sx.svg);
  }
  .flag-icon-sx.flag-icon-squared,
  .flag-icon-sxm.flag-icon-squared {
    background-image: url(/images/flags/1x1/sx.svg);
  }
  .flag-icon-sy,
  .flag-icon-syr {
    background-image: url(/images/flags/4x3/sy.svg);
  }
  .flag-icon-sy.flag-icon-squared,
  .flag-icon-syr.flag-icon-squared {
    background-image: url(/images/flags/1x1/sy.svg);
  }
  .flag-icon-sz,
  .flag-icon-swz {
    background-image: url(/images/flags/4x3/sz.svg);
  }
  .flag-icon-sz.flag-icon-squared,
  .flag-icon-swz.flag-icon-squared {
    background-image: url(/images/flags/1x1/sz.svg);
  }
  .flag-icon-tc,
  .flag-icon-tca {
    background-image: url(/images/flags/4x3/tc.svg);
  }
  .flag-icon-tc.flag-icon-squared,
  .flag-icon-tca.flag-icon-squared {
    background-image: url(/images/flags/1x1/tc.svg);
  }
  .flag-icon-td,
  .flag-icon-tcd {
    background-image: url(/images/flags/4x3/td.svg);
  }
  .flag-icon-td.flag-icon-squared,
  .flag-icon-tcd.flag-icon-squared {
    background-image: url(/images/flags/1x1/td.svg);
  }
  .flag-icon-tf,
  .flag-icon-atf {
    background-image: url(/images/flags/4x3/tf.svg);
  }
  .flag-icon-tf.flag-icon-squared,
  .flag-icon-atf.flag-icon-squared {
    background-image: url(/images/flags/1x1/tf.svg);
  }
  .flag-icon-tg,
  .flag-icon-tgo {
    background-image: url(/images/flags/4x3/tg.svg);
  }
  .flag-icon-tg.flag-icon-squared,
  .flag-icon-tgo.flag-icon-squared {
    background-image: url(/images/flags/1x1/tg.svg);
  }
  .flag-icon-th,
  .flag-icon-tha {
    background-image: url(/images/flags/4x3/th.svg);
  }
  .flag-icon-th.flag-icon-squared,
  .flag-icon-tha.flag-icon-squared {
    background-image: url(/images/flags/1x1/th.svg);
  }
  .flag-icon-tj,
  .flag-icon-tjk {
    background-image: url(/images/flags/4x3/tj.svg);
  }
  .flag-icon-tj.flag-icon-squared,
  .flag-icon-tjk.flag-icon-squared {
    background-image: url(/images/flags/1x1/tj.svg);
  }
  .flag-icon-tk,
  .flag-icon-tkl {
    background-image: url(/images/flags/4x3/tk.svg);
  }
  .flag-icon-tk.flag-icon-squared,
  .flag-icon-tkl.flag-icon-squared {
    background-image: url(/images/flags/1x1/tk.svg);
  }
  .flag-icon-tl,
  .flag-icon-tls {
    background-image: url(/images/flags/4x3/tl.svg);
  }
  .flag-icon-tl.flag-icon-squared,
  .flag-icon-tls.flag-icon-squared {
    background-image: url(/images/flags/1x1/tl.svg);
  }
  .flag-icon-tm,
  .flag-icon-tkm {
    background-image: url(/images/flags/4x3/tm.svg);
  }
  .flag-icon-tm.flag-icon-squared,
  .flag-icon-tkm.flag-icon-squared {
    background-image: url(/images/flags/1x1/tm.svg);
  }
  .flag-icon-tn,
  .flag-icon-tun {
    background-image: url(/images/flags/4x3/tn.svg);
  }
  .flag-icon-tn.flag-icon-squared,
  .flag-icon-tun.flag-icon-squared {
    background-image: url(/images/flags/1x1/tn.svg);
  }
  .flag-icon-to,
  .flag-icon-ton {
    background-image: url(/images/flags/4x3/to.svg);
  }
  .flag-icon-to.flag-icon-squared,
  .flag-icon-ton.flag-icon-squared {
    background-image: url(/images/flags/1x1/to.svg);
  }
  .flag-icon-tr,
  .flag-icon-tur {
    background-image: url(/images/flags/4x3/tr.svg);
  }
  .flag-icon-tr.flag-icon-squared,
  .flag-icon-tur.flag-icon-squared {
    background-image: url(/images/flags/1x1/tr.svg);
  }
  .flag-icon-tt,
  .flag-icon-tto {
    background-image: url(/images/flags/4x3/tt.svg);
  }
  .flag-icon-tt.flag-icon-squared,
  .flag-icon-tto.flag-icon-squared {
    background-image: url(/images/flags/1x1/tt.svg);
  }
  .flag-icon-tv,
  .flag-icon-tuv {
    background-image: url(/images/flags/4x3/tv.svg);
  }
  .flag-icon-tv.flag-icon-squared,
  .flag-icon-tuv.flag-icon-squared {
    background-image: url(/images/flags/1x1/tv.svg);
  }
  .flag-icon-tw,
  .flag-icon-twn {
    background-image: url(/images/flags/4x3/tw.svg);
  }
  .flag-icon-tw.flag-icon-squared,
  .flag-icon-twn.flag-icon-squared {
    background-image: url(/images/flags/1x1/tw.svg);
  }
  .flag-icon-tz,
  .flag-icon-tza {
    background-image: url(/images/flags/4x3/tz.svg);
  }
  .flag-icon-tz.flag-icon-squared,
  .flag-icon-tza.flag-icon-squared {
    background-image: url(/images/flags/1x1/tz.svg);
  }
  .flag-icon-ua,
  .flag-icon-ukr {
    background-image: url(/images/flags/4x3/ua.svg);
  }
  .flag-icon-ua.flag-icon-squared,
  .flag-icon-ukr.flag-icon-squared {
    background-image: url(/images/flags/1x1/ua.svg);
  }
  .flag-icon-ug,
  .flag-icon-uga {
    background-image: url(/images/flags/4x3/ug.svg);
  }
  .flag-icon-ug.flag-icon-squared,
  .flag-icon-uga.flag-icon-squared {
    background-image: url(/images/flags/1x1/ug.svg);
  }
  .flag-icon-um,
  .flag-icon-umi {
    background-image: url(/images/flags/4x3/um.svg);
  }
  .flag-icon-um.flag-icon-squared,
  .flag-icon-umi.flag-icon-squared {
    background-image: url(/images/flags/1x1/um.svg);
  }
  .flag-icon-us,
  .flag-icon-usa {
    background-image: url(/images/flags/4x3/us.svg);
  }
  .flag-icon-us.flag-icon-squared,
  .flag-icon-usa.flag-icon-squared {
    background-image: url(/images/flags/1x1/us.svg);
  }
  .flag-icon-uy,
  .flag-icon-ury {
    background-image: url(/images/flags/4x3/uy.svg);
  }
  .flag-icon-uy.flag-icon-squared,
  .flag-icon-ury.flag-icon-squared {
    background-image: url(/images/flags/1x1/uy.svg);
  }
  .flag-icon-uz,
  .flag-icon-uzb {
    background-image: url(/images/flags/4x3/uz.svg);
  }
  .flag-icon-uz.flag-icon-squared,
  .flag-icon-uzb.flag-icon-squared {
    background-image: url(/images/flags/1x1/uz.svg);
  }
  .flag-icon-va,
  .flag-icon-vat {
    background-image: url(/images/flags/4x3/va.svg);
  }
  .flag-icon-va.flag-icon-squared,
  .flag-icon-vat.flag-icon-squared {
    background-image: url(/images/flags/1x1/va.svg);
  }
  .flag-icon-vc,
  .flag-icon-vct {
    background-image: url(/images/flags/4x3/vc.svg);
  }
  .flag-icon-vc.flag-icon-squared,
  .flag-icon-vct.flag-icon-squared {
    background-image: url(/images/flags/1x1/vc.svg);
  }
  .flag-icon-ve,
  .flag-icon-ven {
    background-image: url(/images/flags/4x3/ve.svg);
  }
  .flag-icon-ve.flag-icon-squared,
  .flag-icon-ven.flag-icon-squared {
    background-image: url(/images/flags/1x1/ve.svg);
  }
  .flag-icon-vg,
  .flag-icon-vgb {
    background-image: url(/images/flags/4x3/vg.svg);
  }
  .flag-icon-vg.flag-icon-squared,
  .flag-icon-vgb.flag-icon-squared {
    background-image: url(/images/flags/1x1/vg.svg);
  }
  .flag-icon-vi,
  .flag-icon-vir {
    background-image: url(/images/flags/4x3/vi.svg);
  }
  .flag-icon-vi.flag-icon-squared,
  .flag-icon-vir.flag-icon-squared {
    background-image: url(/images/flags/1x1/vi.svg);
  }
  .flag-icon-vn,
  .flag-icon-vnm {
    background-image: url(/images/flags/4x3/vn.svg);
  }
  .flag-icon-vn.flag-icon-squared,
  .flag-icon-vnm.flag-icon-squared {
    background-image: url(/images/flags/1x1/vn.svg);
  }
  .flag-icon-vu,
  .flag-icon-vut {
    background-image: url(/images/flags/4x3/vu.svg);
  }
  .flag-icon-vu.flag-icon-squared,
  .flag-icon-vut.flag-icon-squared {
    background-image: url(/images/flags/1x1/vu.svg);
  }
  .flag-icon-wf,
  .flag-icon-wlf {
    background-image: url(/images/flags/4x3/wf.svg);
  }
  .flag-icon-wf.flag-icon-squared,
  .flag-icon-wlf.flag-icon-squared {
    background-image: url(/images/flags/1x1/wf.svg);
  }
  .flag-icon-ws,
  .flag-icon-wsm {
    background-image: url(/images/flags/4x3/ws.svg);
  }
  .flag-icon-ws.flag-icon-squared,
  .flag-icon-wsm.flag-icon-squared {
    background-image: url(/images/flags/1x1/ws.svg);
  }
  .flag-icon-ye,
  .flag-icon-yem {
    background-image: url(/images/flags/4x3/ye.svg);
  }
  .flag-icon-ye.flag-icon-squared,
  .flag-icon-yem.flag-icon-squared {
    background-image: url(/images/flags/1x1/ye.svg);
  }
  .flag-icon-yt,
  .flag-icon-myt {
    background-image: url(/images/flags/4x3/yt.svg);
  }
  .flag-icon-yt.flag-icon-squared,
  .flag-icon-myt.flag-icon-squared {
    background-image: url(/images/flags/1x1/yt.svg);
  }
  .flag-icon-za,
  .flag-icon-zaf {
    background-image: url(/images/flags/4x3/za.svg);
  }
  .flag-icon-za.flag-icon-squared,
  .flag-icon-zaf.flag-icon-squared {
    background-image: url(/images/flags/1x1/za.svg);
  }
  .flag-icon-zm,
  .flag-icon-zmb {
    background-image: url(/images/flags/4x3/zm.svg);
  }
  .flag-icon-zm.flag-icon-squared,
  .flag-icon-zmb.flag-icon-squared {
    background-image: url(/images/flags/1x1/zm.svg);
  }
  .flag-icon-zw,
  .flag-icon-zwe {
    background-image: url(/images/flags/4x3/zw.svg);
  }
  .flag-icon-zw.flag-icon-squared,
  .flag-icon-zwe.flag-icon-squared {
    background-image: url(/images/flags/1x1/zw.svg);
  }
  .flag-icon-eng {
    background-image: url(/images/flags/4x3/eng.svg);
  }
  .flag-icon-eng.flag-icon-squared {
    background-image: url(/images/flags/1x1/eng.svg);
  }
  .flag-icon-sco {
    background-image: url(/images/flags/4x3/sco.svg);
  }
  .flag-icon-sco.flag-icon-squared {
    background-image: url(/images/flags/1x1/sco.svg);
  }